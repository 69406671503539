<template>
  <v-container>
    <div>共{{ total }}个包含“{{ $route.query.q }}”的课程</div>
    <v-row class="mt-2">
      <v-col v-for="(item, index) in list" :key="index" cols="6" md="3">
        <v-card class="pointer" elevation="2" @click="toDetail(item.id)">
          <v-responsive :aspect-ratio="7 / 4">
            <v-img class="img grey" :src="item.cover" />
          </v-responsive>
          <div class="py-3 px-2 text-body-2 text-justify font-weight-bold text-truncate">
            {{ item.title }}
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-pagination
      class="float-right py-10"
      v-model="queryParams.pageNum"
      :length="length"
      :total-visible="7"
      @input="() => search(this.$route.query.q, this.$route.query.type)"
    />
  </v-container>
</template>

<script>
import * as api_home from "@/api/home";
export default {
  name: "SearchResult",
  data() {
    return {
      // 查询参数
      queryParams: { pageNum: 1, pageSize: 10 },
      // 搜索结果列表
      list: [],
      // 搜索结果总数量
      total: 0,
    };
  },
  computed: {
    // 分页总页数
    length() {
      return Math.ceil(this.total / this.queryParams.pageSize);
    },
  },
  watch: {
    $route: function (newValue) {
      const title = newValue.query.q;
      const itemType = newValue.query.type;
      this.search(title, itemType);
    },
  },
  created() {
    this.search(this.$route.query.q, this.$route.query.type);
  },
  methods: {
    // 搜索
    async search(title, itemType) {
      const params = { title, itemType, ...this.queryParams };
      const res = await api_home.search(params);
      if (res.code !== 200) {
        this.$dialog.notify.error(res.msg, { position: "top-right", timeout: 2000 });
        return;
      }
      this.list = res.rows || [];
      this.total = res.total || 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.img {
  width: 100%;
  height: 100%;
}
</style>