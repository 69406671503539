<template>
  <v-app>
    <app-bar></app-bar>
    <v-main class="bg-body">
      <m-breadcrumbs></m-breadcrumbs>
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>
    <app-footer />
  </v-app>
</template>

<script>
import AppBar from "./components/AppBar.vue";
import AppFooter from "./components/AppFooter.vue";
import MBreadcrumbs from "@/components/breadcrumbs";
export default {
  name: "IndexPage",
  components: { AppBar, AppFooter, MBreadcrumbs },
};
</script>

<style>
</style>