import request from './';

/**
 * 获取动态列表
 * @param {*} params 
 * @returns 
 */
export function getList(params) {
    return request({
        url: 'system/category/item/list/item/10',
        method: 'get',
        params,
    })
}
/**
 * 获取动态详情
 * @param {*} id 
 * @returns 
 */
export function getDetail(id) {
    return request({
        url: `system/item/${id}`,
        method: 'get',
    })
}
/**
 * 动态点赞
 * @param {*} id 
 * @returns 
 */
export function like(id) {
    return request({
        url: `system/like`,
        method: 'post',
        data: { likedId: id, likedType: 'ITEM_LIKE', likedStatus: 1 }
    })
}
/**
 * 动态取消点赞
 * @param {*} id 
 * @returns 
 */
export function unlike(id) {
    return request({
        url: `system/like`,
        method: 'post',
        data: { likedId: id, likedType: 'ITEM_LIKE', likedStatus: 0 }
    })
}
/**
 * 获取评论列表
 * @param {*} id 
 * @returns 
 */
export function getCommentList(data) {
    return request({
        url: `system/comment/list`,
        method: 'get',
        params: data
    })
}
/**
 * 提交评论
 * @param {*} data 
 * @returns 
 */
export function submitComment(data) {
    return request({
        url: `system/comment/add`,
        method: 'post',
        data
    })
}

/**
 * 评论点赞
 * @param {*} id 
 * @returns 
 */
export function likeComment(id) {
    return request({
        url: `system/like`,
        method: 'post',
        data: { likedId: id, likedType: 'COMMENT_LIKE', likedStatus: 1 }
    })
}
/**
 * 评论取消点赞
 * @param {*} id 
 * @returns 
 */
export function unlikeComment(id) {
    return request({
        url: `system/like`,
        method: 'post',
        data: { likedId: id, likedType: 'COMMENT_LIKE', likedStatus: 0 }
    })
}
/**
 * 提交评论回复
 * @param {*} data 
 * @returns 
 */
export function submitCommentReply(data) {
    return request({
        url: `system/reply/add`,
        method: 'post',
        data
    })
}

/**
 * 评论回复点赞
 * @param {*} id 
 * @returns 
 */
export function likeReply(id) {
    return request({
        url: `system/like`,
        method: 'post',
        data: { likedId: id, likedType: 'REPLY_LIKE', likedStatus: 1 }
    })
}
