import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layouts'

import Home from "@/views/home"
import Trends from "@/views/trends"
import TrendsDetail from "@/views/trends/detail"
import PolicyDocument from '@/views/policy-document'
import PolicyDocumentDetail from '@/views/policy-document/detail'
import OnlineClassroom from '@/views/online-classroom'
import OnlineClassroomDetail from '@/views/online-classroom/detail'
import TeachWell from '@/views/teach-well'
// import TeachWellDetail from '@/views/teach-well/detail'
import ProjectIntroduction from '@/views/project-introduction'
import Search from '@/views/search'
import VideoDetail from '@/views/policy-document/videoDetail'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/',
        name: '首页',
        component: Home,
        meta: { title: '首页' }
      }
    ]
  },
  {
    path: '/trends',
    component: Layout,
    meta: { title: '工作动态' },
    children: [
      {
        path: '/',
        name: '工作动态',
        component: Trends,
      },
      {
        path: 'detail',
        name: '动态详情',
        component: TrendsDetail,
        meta: { title: '动态详情' }
      }
    ]
  },
  {
    path: '/policyDocument',
    component: Layout,
    meta: { title: '政策文件' },
    children: [
      {
        path: '/',
        name: '政策文件',
        component: PolicyDocument,
      },
      {
        path: 'detail',
        name: '政策解读',
        component: PolicyDocumentDetail,
        meta: { title: '政策解读' }
      },
    ]
  },
  {
    path: '/onlineClassroom',
    component: Layout,
    meta: { title: '在线课堂' },
    children: [
      {
        path: '/',
        name: '在线课堂',
        component: OnlineClassroom,
      },
      {
        path: 'detail',
        name: '课程详情',
        component: OnlineClassroomDetail,
        meta: { title: '课程详情' }
      }
    ]
  },
  {
    path: '/teachWell',
    component: Layout,
    meta: { title: '教子有方' },
    children: [
      {
        path: '/',
        name: '教子有方',
        component: TeachWell,
      },
      {
        path: 'detail',
        name: '课程详情',
        component: VideoDetail,
        meta: { title: '课程详情' }
      }
    ]
  },
  {
    path: '/projectIntroduction',
    component: Layout,
    meta: { title: '项目介绍' },
    children: [
      {
        path: '/',
        name: '项目介绍',
        component: ProjectIntroduction,
      }
    ]
  },
  {
    path: '/search',
    component: Layout,
    meta: { title: '搜索结果' },
    children: [
      {
        path: '/',
        name: '搜索结果',
        component: Search,
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `${to.name} - ${process.env.VUE_APP_TITLE}`
  next()
})
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

export default router
