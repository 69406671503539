<template>
  <div>
    <v-container>
      <v-row class="align-center justify-space-between">
        <v-col cols="6" md="3">
          <a href="/">
            <v-img src="../../assets/png/logo.png" width="184px" height="75px"></v-img>
          </a>
        </v-col>
        <v-col class="d-none d-md-block" md="6">
          <div class="search-bar pl-4 rounded-xl d-flex align-center overflow-hidden">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <div class="px-md-3 pt-md-1 d-flex align-center text-no-wrap" v-bind="attrs" v-on="on">
                  课程<v-icon>mdi-chevron-down</v-icon>
                </div>
              </template>
              <!-- <v-list>
                <v-list-item v-for="(item, index) in searchTypeList" :key="index">
                  <v-list-item-title class="pointer">{{ item.name }}</v-list-item-title>
                  <a>{{ item.name }}</a>
                </v-list-item>
              </v-list> -->
            </v-menu>
            <v-text-field v-model="keyword" placeholder="请输入关键词" filled rounded dense></v-text-field>
            <v-btn color="primary" class="rounded-0" style="height: 43px" elevation="0" @click="onSearch">
              <v-icon class="mr-1">mdi-magnify</v-icon>搜索
            </v-btn>
          </div>
        </v-col>
        <v-col cols="6" md="3">
          <div class="float-right">
            <div v-if="$store.getters.avatar" class="d-flex align-center">
              <span class="nickname mr-2 text-truncate">{{ $store.getters.nickName }}</span>
              <img class="avatar" :src="$store.getters.avatar" alt="" />
            </div>
            <div v-else>
              <v-btn plain :ripple="false" @click="handleLogin">登录</v-btn>
              <v-btn plain :ripple="false" @click="handleRegister">注册</v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <div class="search-bar mt-1 pl-4 rounded-xl d-md-none align-center overflow-hidden d-flex">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <div class="px-md-3 pt-md-1 d-flex align-center text-no-wrap" v-bind="attrs" v-on="on">
              课程<v-icon>mdi-chevron-down</v-icon>
            </div>
          </template>
          <!-- <v-list>
            <v-list-item v-for="(item, index) in searchTypeList" :key="index">
              <v-list-item-title class="pointer">{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list> -->
        </v-menu>
        <input v-model="keyword" type="text" placeholder="请输入关键词" />
        <v-btn color="primary" class="rounded-0" style="height: 43px" elevation="0" @click="onSearch">
          <v-icon class="mr-1">mdi-magnify</v-icon>搜索
        </v-btn>
      </div>
    </v-container>
    <login-page :visible.sync="loginVisible" @on-register="toRegister" />
    <register-page :visible.sync="registerVisible" @to-login="toLoginHandler" />
  </div>
</template>

<script>
import LoginPage from "@/views/login/login.vue";
import RegisterPage from "@/views/login/register.vue";
export default {
  name: "AppBar",
  components: { LoginPage, RegisterPage },
  data() {
    return {
      // 搜索类型
      searchTypeList: [
        { name: "课程", id: 12 },
        { name: "动态", id: 10 },
      ],
      // 是否显示登录
      loginVisible: false,
      // 是否显示注册
      registerVisible: false,
      // 搜索关键字
      keyword: "",
    };
  },
  created() {},
  methods: {
    // 搜索
    onSearch() {
      this.$router.push({ path: "/search", query: { type: 12, q: this.keyword } });
    },
    // 登录按钮点击
    handleLogin() {
      this.loginVisible = true;
    },
    // 注册按钮点击
    handleRegister() {
      this.registerVisible = true;
    },
    // 去注册
    toRegister() {
      this.loginVisible = false;
      this.registerVisible = true;
    },
    // 去登录
    toLoginHandler() {
      this.registerVisible = false;
      this.loginVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-bar {
  border: 1px solid rgba(0, 0, 0, 0.4);
  ::v-deep .v-input__slot {
    background-color: #fff !important;
    margin-bottom: 0;
    padding: 4px;
  }
  ::v-deep .v-text-field__details {
    display: none;
  }

  input {
    flex: 1;
    &:focus-visible {
      outline: none;
    }
  }
}
.nickname {
  width: 80px;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  overflow: hidden;
}
</style>