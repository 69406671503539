<template>
  <v-overlay :value="visible">
    <div class="content rounded">
      <img src="../../assets/png/logo.png" width="164" height="67" />
      <v-tabs
        v-model="tab"
        class="tabs"
        background-color="white"
        slider-color="#4AAF3D"
        slider-size="3"
        centered
      >
        <v-tab><span>账户登录</span></v-tab>
        <div style="flex: 1"></div>
        <v-tab><span>短信登录</span></v-tab>
        <v-tabs-items class="pt-2" v-model="tab">
          <!-- 账户登录 -->
          <v-tab-item>
            <div class="input d-flex align-center">
              <v-icon class="mr-2">mdi-account-outline</v-icon>
              <input v-model="username" type="text" placeholder="手机号/邮箱" />
            </div>
            <div class="input d-flex align-center">
              <v-icon class="mr-2">mdi-lock-outline</v-icon>
              <input v-model="password" type="password" placeholder="密码" />
            </div>
            <div class="mt-2 d-flex align-center justify-space-between text-body-2">
              <div>
                <v-checkbox :ripple="false" dense hide-details v-model="checkbox" label="记住我"></v-checkbox>
              </div>
              <a>忘记密码?</a>
            </div>
            <div class="login-btn pointer rounded" @click="handleLogin">立即登录</div>
            <div class="mt-2 d-flex justify-space-between text-body-2">
              <div>
                <span class="text--disabled">还没有账号？</span>
                <a class="text-decoration-none" @click="toRegister">注册</a>
              </div>
              <a class="grey--text text-body-2" @click="$emit('update:visible', false)">稍后登录</a>
            </div>
            <div class="mt-4 d-flex justify-center align-center text-body-2">
              <v-divider />
              <div class="px-2"><span class="text--disabled">第三方登录</span></div>
              <v-divider />
            </div>
            <div class="mt-4 d-flex align-center justify-center">
              <div class="three-btn">
                <img src="../../assets/png/weixin_logo.png" />
              </div>
            </div>
          </v-tab-item>
          <!-- 短信登录 -->
          <v-tab-item>
            <div class="input d-flex align-center">
              <v-icon class="mr-2">mdi-cellphone</v-icon>
              <input v-model="mobile" type="text" placeholder="手机号" />
            </div>
            <div class="input d-flex align-center">
              <v-icon class="mr-2">mdi-message-alert-outline</v-icon>
              <input v-model="code" type="number" placeholder="验证码" />
              <a class="text-body-2" @click="getCode">获取验证码</a>
            </div>
            <div class="mt-2 d-flex align-center justify-space-between text-body-2">
              <div>
                <v-checkbox :ripple="false" dense hide-details v-model="checkbox" label="记住我"></v-checkbox>
              </div>
              <a>忘记密码?</a>
            </div>
            <div class="login-btn pointer rounded" @click="handleLogin">立即登录</div>
            <div class="mt-2 d-flex justify-space-between text-body-2">
              <div>
                <span class="text--disabled">还没有账号？</span>
                <a class="text-decoration-none" @click="toRegister">注册</a>
              </div>
              <a class="grey--text text-body-2" @click="$emit('update:visible', false)">稍后登录</a>
            </div>
            <div class="mt-4 d-flex justify-center align-center text-body-2">
              <v-divider />
              <div class="px-2"><span class="text--disabled">第三方登录</span></div>
              <v-divider />
            </div>
            <div class="mt-4 d-flex align-center justify-center">
              <div class="three-btn">
                <img src="../../assets/png/weixin_logo.png" />
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>
  </v-overlay>
</template>

<script>
export default {
  name: "LoginPage",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tab: null,
      // 记住我
      checkbox: false,
      // 用户名
      username: "18631048367@163.com",
      // 密码
      password: "123456",
      // 手机号
      mobile: "",
      // 验证码
      code: "",
    };
  },
  methods: {
    async handleLogin() {
      if (!this.username) {
        this.$dialog.notify.error("手机号或邮箱不能为空", { position: "top-right", timeout: 2000 });
        return;
      }
      if (!this.password) {
        this.$dialog.notify.error("密码不能为空", { position: "top-right", timeout: 2000 });
        return;
      }
      const params = { username: this.username, password: this.password };
      this.$store.dispatch("user/login", params).then(() => {
        this.$dialog.notify.success("登录成功", { position: "top-right", timeout: 2000 });
        setTimeout(() => {
          this.$emit("update:visible", false);
        }, 1000);
      });
    },
    // 去注册
    toRegister() {
      this.$emit("on-register");
    },
    // 获取验证码
    getCode() {},
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 22px 32px;
  background-color: white;
  width: 380px;
  height: 530px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .tabs {
    ::v-deep .v-slide-group__wrapper {
      position: relative;
    }
    ::v-deep .v-slide-group__wrapper::after {
      content: "";
      position: absolute;
      height: 0.5px;
      left: 0;
      right: 0;
      bottom: 1px;
      background-color: grey;
    }
    span {
      color: $primary;
    }
    ::v-deep .v-input--selection-controls__ripple {
      margin: 0;
      padding: 0;
    }
    ::v-deep .v-input--selection-controls {
      margin-top: 0;
      padding-top: 0;
    }
    ::v-deep .v-input--selection-controls__input {
      margin-right: 2px;
    }
    ::v-deep label {
      font-size: 0.875rem;
    }
  }
  .input {
    margin-top: 16px;
    border: 1px solid #dbdbdb;
    padding: 8px 6px;
    border-radius: 4px;
    input {
      flex: 1;
    }
    input:focus-visible {
      outline: none;
    }
  }
  .login-btn {
    margin-top: 30px;
    width: 100%;
    background-color: $primary;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .three-btn {
    cursor: pointer;
    img {
      width: 40px;
      height: 40px;
    }
  }
}
</style>
