import request from './';


/**
 * 获取列表
 * @param {*} params
 * @returns
 */
export function getInfo(id) {
    return request({
        url: '/system/teacher/' + id,
        method: 'get',
        id,
    })
}
