<template>
  <v-container class="py-0">
    <v-breadcrumbs v-if="show" class="px-0" divider=">">
      <v-breadcrumbs-item v-for="(item, index) in levelList" :key="item.path">
        <span v-if="item.redirect === 'noRedirect' || index == levelList.length - 1" class="no-redirect">
          {{ item.meta.title }}
        </span>
        <div v-else class="d-flex align-center">
          <a @click.prevent="handleLink(item)">{{ item.meta.title }}</a>
          <div class="ml-3 mr-0">></div>
        </div>
      </v-breadcrumbs-item>
    </v-breadcrumbs>
  </v-container>
</template>

<script>
import { compile } from "path-to-regexp";
export default {
  name: "MBreadcrumbs",
  data() {
    return {
      levelList: null,
      show: false,
    };
  },
  watch: {
    $route(route) {
      this.show = !this.isHome(route);
      if (route.path.startsWith("/redirect/")) {
        return;
      }
      this.getBreadcrumb();
    },
  },
  created() {
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb() {
      let matched = this.$route.matched.filter((item) => item.meta && item.meta.title);
      const first = matched[0];

      if (!this.isHome(first)) {
        matched = [{ path: "/", meta: { title: "首页" } }].concat(matched);
      }

      this.levelList = matched.filter(
        (item) => item.meta && item.meta.title && item.meta.breadcrumb !== false
      );
    },
    isHome(route) {
      const name = route && route.name;
      if (!name) {
        return false;
      }
      return name.trim().toLocaleLowerCase() === "Home".toLocaleLowerCase() || name === "首页";
    },
    pathCompile(path) {
      const { params } = this.$route;
      var toPath = compile(path);
      return toPath(params);
    },
    handleLink(item) {
      const { redirect, path } = item;
      if (redirect) {
        this.$router.push(redirect);
        return;
      }
      this.$router.push(this.pathCompile(path));
    },
  },
};
</script>

<style lang="scss" scoped>
.no-redirect {
  color: #97a8be;
  cursor: text;
}
</style>