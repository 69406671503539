<template>
  <div class="reply-item-container mt-3 mt-md-5">
    <v-avatar color="primary" size="42"> <img :src="item.fromAvatar" alt="" /></v-avatar>
    <div class="ml-2 ml-md-4 flex-grow-1">
      <div class="text-subtitle-1 text-md-h6 font-weight-bold" style="line-height: 1.2">
        <span>{{ item.fromNickName || "注销用户" }}</span>
        <span class="text-body-2"> 回复 </span>
        <span>{{ item.toNickName || "注销用户" }}</span>
      </div>
      <span class="text-body-2">{{ item.content }}</span>
      <div class="d-flex justify-space-between mt-1 text-body-2 text--disabled">
        <span>{{ dayjs(item.create_time).format("YYYY-MM-DD") }}</span>
        <div class="d-flex align-center">
          <div class="pointer text--disabled" @click="$emit('on-reply', item)">
            {{ showReplyArea ? "收起" : "回复" }}
          </div>
          <div class="pointer text--disabled ml-5" @click="submitLike">
            <v-icon color="grey lighten-1" size="18">mdi-thumb-up</v-icon>
          </div>
          <span class="ml-1">{{ item.likedCount || 0 }}</span>
        </div>
      </div>
      <!-- 回复框 -->
      <div v-if="showReplyArea" class="reply-wrapper">
        <textarea v-model="reply" :placeholder="`回复：${item.fromNickName || '注销用户'}`" />
        <div class="bottm-wrapper">
          <v-btn color="#4AAF3D" @click="submitReply">
            <span class="white--text">评论</span>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { likeReply, submitCommentReply } from "@/api/trends";
export default {
  name: "ReplyItem",
  props: {
    item: {},
    // 是否显示回复区
    showReplyArea: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 评论回复内容
      reply: "",
    };
  },
  created() {},
  methods: {
    dayjs,
    // 点赞
    async submitLike() {
      const res = await likeReply(this.item.id);
      if (res.code !== 200) {
        this.$dialog.notify.error(res.msg, { position: "top-right", timeout: 2000 });
        return;
      }
      this.$emit("on-submit");
    },
    // 提交评论回复
    async submitReply() {
      if (!this.reply) {
        this.$dialog.notify.error("请填写评论内容~");
        return;
      }
      const params = { replyId: this.item.id, replyType: "REPLY", content: this.reply };
      const res = await submitCommentReply(params);
      if (res.code !== 200) {
        this.$dialog.notify.error(res.msg, { position: "top-right", timeout: 2000 });
        return;
      }
      this.reply = "";
      this.$emit("on-submit");
      this.$emit("on-reply", this.item);
    },
  },
};
</script>

<style lang="scss" scoped>
.reply-item-container {
  display: flex;
  flex-direction: row;
  .reply-wrapper {
    margin-top: 10px;
    padding: 18px 16px;
    background-color: #f5f5f6;
    border-radius: 8px;
    textarea {
      width: 100%;
      height: 60px;
      &:focus-visible {
        outline: none;
      }
    }
    .bottm-wrapper {
      display: flex;
      flex-direction: row-reverse;
    }
  }
}
</style>