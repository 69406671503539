/**
 * 封装Axios
 * 处理请求、响应错误信息
 */
import store from '@/store';
import axios from 'axios';

// axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000
});

// request interceptor
service.interceptors.request.use(
  config => {
    if (store.getters.token) {
      config.headers['Authorization'] = 'Bearer ' + store.getters.token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  response => {
    if (response.status === 200) {
      return response.data
    } else {
      return Promise.reject(new Error(response.msg || 'Error'));
    }
  },
  error => {
    return Promise.reject(error);
  }
);

export default service;
