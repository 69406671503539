<template>
  <div>
    <!-- banner -->
    <!-- <carousel /> -->
    <v-container>
      <m-title title="工作动态" border />
      <div v-if="trendsList.length > 0" class="list">
        <div
          class="trends-item pt-4 pt-md-11 pb-2 pb-md-4"
          v-for="item in trendsList"
          :key="item.id"
          @click="toDetail(item.id)"
        >
          <div class="text-h6" type="heading">{{ item.title }}</div>
          <div class="content mt-1 mt-md-4 text-body-1 text--secondary text-justify" type="sentences">
            {{ item.content }}
          </div>
          <div class="d-flex justify-end align-center mt-2 mt-md-4 text--disabled text-body-2">
            <div type="text" width="100">阅读量：{{ item.viewCount || 0 }}</div>
            <div class="ml-6" type="text" width="100">讨论量：{{ item.replyCount || 0 }}</div>
            <div class="ml-6" type="text" width="100">{{ item.date }}</div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="trends-item pt-4 pt-md-11 pb-2 pb-md-4" v-for="item in [1, 2, 3, 4, 5]" :key="item.id">
          <v-skeleton-loader class="text-h6" :loading="false" type="heading"></v-skeleton-loader>
          <v-skeleton-loader class="mt-4 mt-md-8" :loading="false" type="text"></v-skeleton-loader>
          <v-skeleton-loader class="mt-1" :loading="false" width="800" type="text"></v-skeleton-loader>
          <div class="d-flex justify-end align-center mt-4">
            <v-skeleton-loader type="text" :loading="false" width="100"></v-skeleton-loader>
            <v-skeleton-loader class="ml-6" :loading="false" type="text" width="100"></v-skeleton-loader>
            <v-skeleton-loader class="ml-6" :loading="false" type="text" width="100"></v-skeleton-loader>
          </div>
        </div>
      </div>
      <v-pagination
        class="float-right py-4 py-md-10"
        v-model="queryParams.pageNum"
        :length="length"
        :total-visible="7"
        @input="() => getList()"
      />
    </v-container>
  </div>
</template>

<script>
// import Carousel from "@/layouts/Carousel";
import MTitle from "@/components/title";
// import dayjs from "dayjs";
import * as api_trends from "@/api/trends.js";
export default {
  name: "WorkTrends",
  components: { MTitle },
  data() {
    return {
      // 查询参数
      queryParams: { pageNum: 1, pageSize: 5 },
      // 动态的总数量
      total: 0,
      // 动态列表
      trendsList: [],
    };
  },
  computed: {
    // 分页总页数
    length() {
      return Math.ceil(this.total / this.queryParams.pageSize);
    },
  },
  created() {
    this.getList();
  },
  methods: {
    // 获取动态列表
    async getList() {
      const params = { ...this.queryParams };
      try {
        const res = await api_trends.getList(params);
        if (res.code !== 200) {
          return;
        }
        this.trendsList = res.rows;
        this.total = res.total;
      } catch (error) {
        console.log(error);
      }
    },
    // 跳转到动态详情
    toDetail(id) {
      this.$router.push({ path: "/trends/detail", query: { id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  .trends-item {
    cursor: pointer;
    .content {
      line-height: 2;
      text-overflow: ellipsis;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -moz-box;
      -moz-line-clamp: 2;
      -moz-box-orient: vertical;
      overflow-wrap: break-word;
      word-break: break-all;
      white-space: normal;
      overflow: hidden;
    }
  }
}
</style>