/**
 * 项目介绍
 */

import request from './';

/**
 * 获取项目介绍列表
 * @param {*} params 
 */
export function getList(params) {
    return request({
        url: 'system/category/item/list/item/16',
        method: 'get',
        params,
    })
}