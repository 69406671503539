<template>
  <v-carousel hide-delimiter-background cycle interval="5000">
    <v-carousel-item
      class="item"
      v-for="(item, i) in list"
      :key="i"
      :src="item.picUrl"
      reverse-transition="fade-transition"
      transition="fade-transition"
    ></v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: "m-carousel",
  props: {
    list: [],
  },
  data() {
    return {};
  },
};
</script>

<style  lang="scss" scoped>
.item {
  width: 100%;
  aspect-ratio: 1080 / 409;
}
</style>