<template>
  <v-container>
    <!-- 标题 -->
    <div class="d-flex flex-column align-center">
      <span class="text-h5 text-md-h4 font-weight-bold">{{ detail?.title }}</span>
      <div class="mt-4 text-body-2 text--disabled">
        <span>日期：{{ dayjs(detail?.createTime).format("YYYY-MM-DD") }}</span>
        <span class="ml-12">阅读量：{{ detail?.viewCount || 0 }}</span>
      </div>
    </div>
    <!-- 内容 -->
    <div class="content mt-4 text-justify" v-html="detail?.content"></div>
    <div class="d-flex flex-column align-center mt-10 mb-4">
      <v-btn outlined fab color="grey" @click="handleLike"><v-icon>mdi-thumb-up</v-icon></v-btn>
      <span class="mt-4 text-body-2 text--disabled">{{ detail?.likeCount || 0 }}人点赞</span>
    </div>
    <v-divider></v-divider>
    <div class="d-flex justify-space-between mt-3 text-body-2 font-weight-bold">
      <!-- <a><span>上一篇：做好2022年全民终身学习活动周工作</span></a>
      <a><span>下一篇：做好2022年全民终身学习活动周工作</span></a> -->
    </div>
    <!-- 评论 -->
    <div class="my-11 px-2 px-md-4">
      <span class="text-body-1 font-weight-bold">发表评论</span>
      <v-textarea
        class="mt-2"
        filled
        no-resize
        :hide-details="true"
        placeholder="评论请登录~"
        name="input-7-1"
        v-model="comment"
        hint=""
        background-color="#F5F5F6"
      ></v-textarea>
      <div class="mt-4 d-flex justify-end">
        <v-btn color="primary" :disabled="!canSubmit" @click="handleComment">发表</v-btn>
      </div>

      <div class="comment-area mt-4">
        <span class="text-body-1 font-weight-bold">评论区（{{ commentTotal }}条）</span>
        <div class="comment-list">
          <CommentItem
            v-for="(item, index) in commentList"
            :key="index"
            :show-reply-area="currentComment?.id === item.id"
            :item="item"
            @on-submit="getCommentList"
            @on-reply="handleReply(item)"
          />
        </div>
        <v-pagination
          class="float-right py-10"
          v-model="commentQueryParams.pageNum"
          :length="length"
          :total-visible="7"
          @input="() => getList()"
        />
      </div>
    </div>
  </v-container>
</template>

<script>
import dayjs from "dayjs";
import * as api_trends from "@/api/trends.js";
import CommentItem from "@/components/commentItem";
export default {
  name: "TrendsDetail",
  components: { CommentItem },
  data() {
    return {
      id: this.$route.query.id,
      // 详情
      detail: null,
      // 评论内容
      comment: "",

      // 查询参数
      commentQueryParams: { pageNum: 1, pageSize: 8 },
      // 评论列表
      commentList: [],
      // 评论总数
      commentTotal: 0,
      // 当前要回复的评论
      currentComment: null,
    };
  },
  computed: {
    // 是否能发表评论
    canSubmit() {
      return this.comment.length > 0;
    },
    // 分页总页数
    length() {
      return Math.ceil(this.commentTotal / this.commentQueryParams.pageSize);
    },
  },
  created() {
    this.getDetail();
    this.getCommentList();
  },
  methods: {
    dayjs,
    // 获取动态详情
    async getDetail() {
      try {
        const res = await api_trends.getDetail(this.id);
        this.detail = res.data;
      } catch (error) {
        console.log(error);
      }
    },
    // 获取评论列表
    async getCommentList() {
      const params = { itemId: this.id, ...this.commentQueryParams };
      const res = await api_trends.getCommentList(params);
      if (res.code === 200) {
        this.commentList = res.rows;
        this.commentTotal = res.total;
      }
    },
    // 点赞
    async handleLike() {
      const res = await api_trends.like(this.id);
      if (res.code !== 200) {
        this.$dialog.notify.error(res.msg, { position: "top-right", timeout: 2000 });
        return;
      }
      this.getDetail();
    },
    // 评论
    async handleComment() {
      const params = { itemId: this.id, content: this.comment };
      const res = await api_trends.submitComment(params);
      if (res.code !== 200) {
        this.$dialog.notify.error(res.msg, { position: "top-right", timeout: 2000 });
        return;
      }
      this.$dialog.notify.success("评论成功~", { position: "top-right", timeout: 2000 });
      this.comment = "";
      this.getCommentList();
    },
    // 回复按钮点击
    handleReply(item) {
      if (this.currentComment?.id === item.id) {
        this.currentComment = null;
      } else {
        this.currentComment = item;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  line-height: 2;
  ::v-deep p {
    text-indent: 2em;
  }
}
.pointer {
  cursor: pointer;
}
</style>