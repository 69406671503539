<template>
  <v-footer class="green white--text py-10">
    <v-container>
      <span class="d-block py-1">关于我们</span>
      <span class="d-block py-1">北京市海淀区北三环西路48号北京科技会展中心1号楼A座7A</span>
      <span class="d-block py-1">联系电话：010-51627401</span>
      <span class="d-block py-1">Copyright©2022  jxgygfh.com .All Rights Reserved <a href="https://beian.miit.gov.cn/" target="_blank" style="color:#ffffff">京ICP备2022033429号-1</a></span>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>

<style>
</style>
