import request from './';

/**
 * 获取banner列表
 * @param {*} params 
 */
export function getBannerList() {
    return request({
        url: 'system/banner/list',
        method: 'get',
    })
}
/**
 * 搜索
 * @param {*} params 
 * @returns 
 */
export function search(params) {
    return request({
        url: 'system/item/list',
        method: 'get',
        params
    })
}
/**
 * 获取列表
 * @param {*} params 
 * @returns 
 */
export function getCourseList(params) {
    return request({
        url: 'system/category/item/list/item/12',
        method: 'get',
        params,
    })
}