import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#4AAF3D',
            secondary: '#FFCDD2',
            accent: '#3F51B5'
          },
        },
    },
});
