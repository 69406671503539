<template>
  <v-container>
    <v-row justify="space-around">
      <v-col cols="6">
        <v-responsive :aspect-ratio="369 / 135">
          <a href="#"
            ><v-img class="img grey rounded-sm rounded-md-xl" src="../../assets/png/leader.png"
          /></a>
        </v-responsive>
      </v-col>
      <v-col cols="6">
        <v-responsive :aspect-ratio="369 / 135">
          <a href="#"
            ><v-img class="img grey rounded-sm rounded-md-xl" src="../../assets/png/policy.png"
          /></a>
        </v-responsive>
      </v-col>
    </v-row>
    <div class="mt-5">
      <m-title title="政策文件" border />
      <div v-if="list.length > 0" class="list">
        <div
          class="trends-item pt-5 pt-md-11 pb-2 pb-md-4"
          v-for="item in list"
          :key="item.id"
          @click="toDetail(item.id)"
        >
          <div class="text-h6" type="heading">{{ item.title }}</div>
          <div class="content mt-2 mt-md-4 text-body-1 text--secondary text-justify" type="sentences">
            {{ item.content }}
          </div>
        </div>
      </div>
      <div v-else>
        <div class="trends-item pt-5 pt-md-11 pb-2 pb-md-4" v-for="item in [1, 2, 3, 4, 5]" :key="item.id">
          <v-skeleton-loader class="text-h6" :loading="false" type="heading"></v-skeleton-loader>
          <v-skeleton-loader class="mt-8" :loading="false" type="text"></v-skeleton-loader>
          <v-skeleton-loader class="mt-1" :loading="false" width="800" type="text"></v-skeleton-loader>
        </div>
      </div>
      <v-pagination
        class="float-right py-5 py-md-10"
        v-model="queryParams.pageNum"
        :length="length"
        :total-visible="7"
        @input="() => getList()"
      />
    </div>
  </v-container>
</template>

<script>
import MTitle from "@/components/title";
import * as api_policy from "@/api/policy-document";
export default {
  name: "PolicyDocument",
  components: { MTitle },
  data() {
    return {
      // 查询参数
      queryParams: { pageNum: 1, pageSize: 5 },
      // 总数量
      total: 0,
      // 政策文件列表
      list: [],
    };
  },
  computed: {
    // 分页总页数
    length() {
      return Math.ceil(this.total / this.queryParams.pageSize);
    },
  },
  created() {
    this.getList();
  },
  methods: {
    // 获取动态列表
    async getList() {
      const params = { ...this.queryParams };
      try {
        const res = await api_policy.getList(params);
        if (res.code !== 200) {
          return;
        }
        this.list = res.rows;
        this.total = res.total;
      } catch (error) {
        console.log(error);
      }
    },
    // 跳转到动态详情
    toDetail(id) {
      this.$router.push({ path: "/policyDocument/detail", query: { id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.img {
  width: 100%;
  height: 100%;
}
.list {
  .trends-item {
    cursor: pointer;
    .content {
      line-height: 2;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -moz-box;
      -moz-line-clamp: 2;
      -moz-box-orient: vertical;
      overflow-wrap: break-word;
      word-break: break-all;
      white-space: normal;
      overflow: hidden;
    }
  }
}
</style>