<template>
  <div class="d-flex align-center py-1 py-md-2" :class="border ? 'border' : ''">
    <div class="divider mr-2"></div>
    <span class="title">{{ title }}</span>
  </div>
</template>

<script>
export default {
  name: "MTitle",
  props: {
    title: {
      type: String,
      default: "",
    },
    border: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.divider {
  width: 2px;
  height: 18px;
  background-color: $primary;
}
.title {
  color: $primary;
}
.border {
  border-bottom: 2px solid $primary;
}
</style>