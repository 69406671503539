<template>
  <v-overlay :value="visible" light>
    <div class="content rounded black--text">
      <span class="mt-4 text-h6 font-weight-bold align-self-start">欢迎注册</span>
      <div class="input">
        <input v-model="email" type="text" placeholder="请输入您的注册邮箱" />
        <v-icon color="grey">mdi-email-outline</v-icon>
      </div>
      <div class="input">
        <input v-model="code" type="text" placeholder="请输入验证码" />
        <a :style="sendCodeSuccess ? 'color: #B9F6CA' : ''" @click="sendCode">
          {{ sendCodeSuccess ? `${interval}s后重新获取` : "邮箱验证" }}
        </a>
      </div>
      <div class="input">
        <input v-model="password" :type="showPassword ? 'text' : 'password'" placeholder="请设置登录密码" />
        <a @click="togglePasswrodVisible">
          <v-icon color="grey">{{ showPassword ? "mdi-eye-off-outline" : "mdi-eye-outline" }}</v-icon>
        </a>
      </div>
      <div class="input">
        <input
          v-model="confirmPassword"
          :type="showConfirmPassword ? 'text' : 'password'"
          placeholder="请再次输入登录密码"
        />
        <a @click="toggleConfirmPasswrodVisible">
          <v-icon color="grey">{{ showConfirmPassword ? "mdi-eye-off-outline" : "mdi-eye-outline" }}</v-icon>
        </a>
      </div>
      <div class="mt-10 d-flex align-center justify-center">
        <v-checkbox :ripple="false" dense hide-details v-model="checkbox" light></v-checkbox>
        <span class="text-caption">
          阅读并同意
          <a href="https://www.jiguang.cn/license/privacy">《用户服务协议》</a>
          和
          <a href="https://www.jiguang.cn/license/privacy">《隐私保护协议》</a>
        </span>
      </div>
      <div class="login-btn pointer rounded" @click="handleRegister">注册</div>
      <div class="mt-2 d-flex align-center justify-space-between text-body-2" style="width: 100%">
        <a class="" @click="$emit('to-login')">去登录</a>
        <a class="grey--text" @click="$emit('update:visible', false)">稍后注册</a>
      </div>
    </div>
  </v-overlay>
</template>

<script>
import * as api_auth from "@/api/auth";
export default {
  name: "RegisterPage",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tab: null,
      email: "",
      code: "",
      password: "",
      confirmPassword: "",
      checkbox: false,

      // 是否显示密码
      showPassword: false,
      // 是否显示确认密码
      showConfirmPassword: false,
      // 间隔多少秒可重新发送邮箱验证码
      interval: 60,
      // 验证码是否发送成功
      sendCodeSuccess: false,
      // 定时器
      timer: null,
    };
  },
  methods: {
    // 注册
    async handleRegister() {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const regExp = new RegExp(pattern);
      if (!regExp.test(this.email)) {
        this.$dialog.notify.error("邮箱格式错误", { position: "top-right", timeout: 2000 });
        return;
      }
      if (!this.code) {
        this.$dialog.notify.error("请输入邮箱验证码", { position: "top-right", timeout: 2000 });
        return;
      }
      if (!this.password) {
        this.$dialog.notify.error("密码不能为空", { position: "top-right", timeout: 2000 });
        return;
      }
      if (!this.confirmPassword) {
        this.$dialog.notify.error("确认密码不能为空", { position: "top-right", timeout: 2000 });
        return;
      }
      if (this.password !== this.confirmPassword) {
        this.$dialog.notify.error("两次密码不一致", { position: "top-right", timeout: 2000 });
        return;
      }
      if (!this.checkbox) {
        this.$dialog.notify.error("请阅读并同意《用户服务协议》和《隐私保护协议》", {
          position: "top-right",
          timeout: 2000,
        });
        return;
      }
      try {
        const params = { username: this.email, password: this.password };
        const res = await api_auth.register(params);
        if (res.code === 200) {
          this.$dialog.notify.success("注册成功", { position: "top-right", timeout: 2000 });
          setTimeout(() => {
            this.$emit("update:visible", false);
          }, 1000);
        } else {
          this.$dialog.notify.error(res.msg, { position: "top-right", timeout: 2000 });
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 切换密码显示
    togglePasswrodVisible() {
      this.showPassword = !this.showPassword;
    },
    // 切换确认密码显示
    toggleConfirmPasswrodVisible() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    // 发送邮箱验证码
    async sendCode() {
      if (this.sendCodeSuccess) {
        return;
      }
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const regExp = new RegExp(pattern);
      if (!regExp.test(this.email)) {
        this.$dialog.notify.error("邮箱格式错误", { position: "top-right", timeout: 2000 });
        return;
      }
      const params = { mail: this.email };
      const res = await api_auth.sendEmailCode(params);
      if (res.code !== 200) {
        this.$dialog.notify.error(res.msg, { position: "top-right", timeout: 2000 });
        return;
      }
      this.$dialog.notify.success("发送成功~", { position: "top-right", timeout: 2000 });
      this.sendCodeSuccess = true;
      this.timer = setInterval(() => {
        if (this.interval === 1) {
          clearInterval(this.timer);
          this.sendCodeSuccess = false;
          this.interval = 60;
          return;
        }
        this.interval -= 1;
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 22px 32px;
  background-color: white;
  width: 380px;
  height: 530px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.input {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  border-bottom: 1px solid #dbdbdb;
  padding: 8px 6px;
  color: black;
  input {
    flex: 1;
  }
  input:focus-visible {
    outline: none;
  }
}
.login-btn {
  color: white;
  margin-top: 50px;
  width: 100%;
  background-color: $primary;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
::v-deep .v-input--selection-controls__ripple {
  margin: 0;
  padding: 0;
}
::v-deep .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
::v-deep .v-input--selection-controls__input {
  margin-right: 2px;
}
</style>