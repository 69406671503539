import { getUserInfo, login } from "@/api/auth"
import { getToken, removeToken, setToken } from "@/utils/auth"


const state = {
  token: getToken(),
  userId: '',
  username: '',
  avatar: '',
  nickName: ''
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USER_ID: (state, userId) => {
    state.userId = userId;
  },
  SET_USER_NAME: (state, username) => {
    state.username = username
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_NICK_NAME: (state, nickName) => {
    state.nickName = nickName;
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password }).then(response => {
        const { token } = response
        commit('SET_TOKEN', token)
        setToken(token)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  // get user info
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getUserInfo().then(response => {
        const { data, code } = response;
        if (code === 20004) {
          // token过期
          removeToken();
          reject('token过期, 请重新登录');
        }
        if (!data) {
          reject('Verification failed, please Login again.')
        }
        const { nickName, userId, avatar, username } = data
        commit('SET_USER_ID', userId)
        commit('SET_USER_NAME', username)
        commit('SET_NICK_NAME', nickName)
        commit('SET_AVATAR', avatar)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
