<template>
  <v-container class="py-0">
    <m-title title="教子有方" />
    <div class="py-2 py-md-4" v-for="(item, index) in [1]" :key="index">
      <!-- <div class="text-h6">职业形象</div> -->
      <v-row class="mt-1">
        <v-col v-for="(item, index) in list" :key="index" cols="6" md="3">
          <v-card class="pointer" elevation="2" @click="toDetail(item.id)">
            <v-responsive :aspect-ratio="7 / 4">
              <v-img class="img grey" :src="item.cover" />
            </v-responsive>
            <div class="py-3 px-2">
              <p class="text-body-2 text-justify font-weight-bold title">
                {{ item.title }}
              </p>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-pagination
      class="float-right py-10"
      v-model="queryParams.pageNum"
      :length="length"
      :total-visible="7"
      @input="() => getList()"
    />
  </v-container>
</template>

<script>
import MTitle from "@/components/title";
import * as api_teach from "@/api/teach-well";
export default {
  name: "TeachWell",
  components: { MTitle },
  data() {
    return {
      // 查询参数
      queryParams: { pageNum: 1, pageSize: 8 },
      list: [],
      // 总数量
      total: 0,
    };
  },
  computed: {
    // 分页总页数
    length() {
      return Math.ceil(this.total / this.queryParams.pageSize);
    },
  },
  created() {
    this.getList();
  },
  methods: {
    // 获取动态列表
    async getList() {
      const params = { ...this.queryParams };
      try {
        const res = await api_teach.getList(params);
        if (res.code !== 200) {
          return;
        }
        this.list = res.rows;
        this.total = res.total;
      } catch (error) {
        console.log(error);
      }
    },
    // 跳转到视频详情
    toDetail(id) {
      this.$router.push({ path: "/teachWell/detail", query: { id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.img {
  width: 100%;
  height: 100%;
}
.pointer {
  cursor: pointer;
}
.title {
  margin-bottom: 0;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* 这里是超出几行省略 */
  overflow: hidden;
}
</style>