<template>
  <div class="pb-10">
    <v-container class="py-1">
      <!-- 导航路由 -->
      <ul class="nav-bar d-flex flex-row py-6 overflow-x-auto">
        <li class="nav-item mr-3 mr-md-9" v-for="(item, index) in routers" :key="item.key">
          <a
            v-if="item.path"
            class="nav-link"
            :class="activeRouterIndex === index ? 'active' : ''"
            @click="navigateTo(item)"
          >
            <span class="text-md-body-1 text-no-wrap">{{ item.title }}</span>
          </a>
          <a
            v-else
            style="color: #000000de"
            href="https://baidu.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span class="text-md-body-1 text-no-wrap">{{ item.title }}</span>
          </a>
        </li>
      </ul>
    </v-container>
    <!-- banner -->
    <carousel :list="bannerList" />
    <v-container>
      <!-- 模块卡片 -->
      <v-row class="py-5 py-md-14">
        <v-col cols="6" md="3">
          <a class="card rounded-lg" @click="$router.push({ path: '/onlineClassroom' })">
            <img src="../../assets/png/banner1.png" alt="" />
          </a>
        </v-col>
        <v-col cols="6" md="3">
          <a class="card rounded-lg" href="https://baidu.com" target="_blank">
            <img src="../../assets/png/banner2.png" alt="" />
          </a>
        </v-col>
        <v-col cols="6" md="3">
          <a class="card rounded-lg" @click="$router.push({ path: '/trends' })">
            <img src="../../assets/png/banner3.png" alt="" />
          </a>
        </v-col>
        <v-col cols="6" md="3">
          <a class="card rounded-lg" @click="$router.push({ path: '/teachWell' })">
            <img src="../../assets/png/banner4.png" alt="" />
          </a>
        </v-col>
      </v-row>
      <!-- 在线课堂 -->
      <div>
        <m-title title="在线课堂" />
        <v-row class="mt-md-3 d-flex align-center flex-wrap">
          <v-col
            class="course-item"
            cols="6"
            md="3"
            v-for="(course, courseIdx) in courseList"
            :key="courseIdx"
            @click="() => toClassroow(course)"
          >
            <v-img class="img mb-2" :src="course.cover" />
            <span>{{ course.title }}</span>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import Carousel from "@/layouts/components/Carousel";
import MTitle from "@/components/title";
import * as api_home from "@/api/home";
export default {
  name: "HomePage",
  components: { Carousel, MTitle },
  data() {
    return {
      // 导航路由
      routers: [
        { key: "home", title: "首页", path: "/" },
        { key: "work-trends", title: "工作动态", path: "/trends" },
        { key: "policy-document", title: "政策文件", path: "/policyDocument" },
        { key: "online-classroom", title: "在线课堂", path: "/onlineClassroom" },
        { key: "case-exchange", title: "案例交流", path: "" },
        { key: "teach-well", title: "教子有方", path: "/teachWell" },
        { key: "certification-test", title: "认证测试", path: "" },
        { key: "project-introduction", title: "项目介绍", path: "/projectIntroduction" },
      ],
      // 当前路由下标
      activeRouterIndex: 0,
      // banner列表
      bannerList: [],
      // 课程列表
      courseList: [],
    };
  },
  created() {
    this.getBannerList();
    this.getCourseList();
  },
  methods: {
    // 获取banner列表
    async getBannerList() {
      const res = await api_home.getBannerList();
      this.bannerList = res.rows;
    },
    // 获取在线课程列表
    async getCourseList() {
      const params = { pageNum: 1, pageSize: 4 };
      const res = await api_home.getCourseList(params);
      if (res.code !== 200) {
        this.$dialog.notify.error(res.msg, { position: "top-right", timeout: 2000 });
        return;
      }
      this.courseList = res.rows || [];
    },
    // 跳转到在线课堂
    toClassroow(item) {
      this.$router.push({ path: "/onlineClassroom/detail", query: { id: item.id } });
    },
    // 跳转
    navigateTo(item) {
      if (item.path) {
        this.$router.push({ path: item.path });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
ul {
  padding: 0;
}
ul.nav-bar {
  .nav-item {
    list-style: none;
    .nav-link {
      color: #000000de;
      &:last-child {
        margin-right: 0px !important;
      }
      &:hover {
        color: $primary;
      }

      &.active {
        color: $primary;
      }
    }
    a {
      text-decoration: none;
    }
  }
}
.card {
  width: 100%;
  aspect-ratio: 260 / 160;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
}
.divider {
  width: 3px;
  height: 16px;
  background-color: $primary;
}
.course-item {
  cursor: pointer;
  .img {
    width: 100%;
    aspect-ratio: 276 / 148;
    background-color: $background;
  }
  span {
    color: #757575;
    font-size: 12px;
  }
}
</style>
