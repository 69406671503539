<template>
  <v-container>
    <div class="text-h5 text-md-h4 font-weight-bold">{{ detail?.title }}</div>
    <v-row class="mt-4" no-gutters>
      <v-col cols="12" md="8">
        <v-responsive class="black" :aspect-ratio="16 / 9" width="100%">
          <video
              ref="video"
              style="width: 100%; aspect-ratio: 16 / 9"
              v-if="currentVideoUrl"
              :src="currentVideoUrl"
              @timeupdate="timeupdate"
              @play="play"
              controls
          />
        </v-responsive>
      </v-col>
      <v-col class="pa-4 right-wrapper grey lighten-2" cols="12" md="4">
        <span v-if="teacherInfo" class="text-h6 font-weight-bold">讲师简介</span>
        <v-card v-if="teacherInfo" class="mt-2 pa-2 d-flex">
          <div>
            <v-img
              class="rounded"
              width="60"
              height="80"
              :src="teacherFace"
            ></v-img>
          </div>
          <div class="flex-grow-1 ml-2">
            <div class="text-body-2 font-weight-bold">{{teacherName}}</div>
            <div class="text-caption" v-for="(key,index) in identityTags" :key="index"> {{ key }} </div>
          </div>
        </v-card>
        <div class="mt-5 text-h6 font-weight-bold">播放列表</div>
        <div class="mt-1">
          <div class="selection-item py-2" v-for="(item, index) in detail?.eduItemVideos" :key="index">
            <div class="d-flex align-center">
              <v-btn @click="handleVideoChange(index)">{{ "0" + (index + 1) }}</v-btn>
              <div class="ml-2">
                <div>{{ item.title }}</div>
                <span class="text--disabled">{{ item.duration || "0:00" }}</span>
                <span class="ml-2 text--disabled">{{ item.learnCount || 0 }}人学过</span>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="mt-4 text-body-1">
      {{ detail?.content }}
    </div>
    <div class="bottom-wrapper mt-8 mb-2 d-flex align-center">
      <div><v-icon>mdi-thumb-up</v-icon></div>
      <span class="ml-1">{{ detail?.likeCount || 0 }}人点赞</span>
      <div class="ml-6"><v-icon>mdi-comment-processing-outline</v-icon></div>
      <span class="ml-1">{{ detail?.replyCount || 0 }}人评论</span>
    </div>
    <v-divider />
    <!-- 评论 -->
    <div class="my-11 px-4">
      <span class="text-body-1 font-weight-bold">发表评论</span>
      <v-textarea
        class="mt-2"
        filled
        no-resize
        :hide-details="true"
        placeholder="评论请登录~"
        name="input-7-1"
        v-model="comment"
        hint=""
        background-color="#F5F5F6"
      ></v-textarea>
      <div class="mt-4 d-flex justify-end">
        <v-btn color="primary" :disabled="!canSubmit" @click="handleComment">发表</v-btn>
      </div>

      <div class="comment-area mt-4">
        <span class="text-body-1 font-weight-bold">评论区（{{ commentTotal }}条）</span>
        <div class="comment-list">
          <CommentItem
            v-for="(item, index) in commentList"
            :key="index"
            :show-reply-area="currentComment?.id === item.id"
            :item="item"
            @on-submit="getCommentList"
            @on-reply="handleReply(item)"
          />
        </div>
        <v-pagination
          class="float-right py-10"
          v-model="commentQueryParams.pageNum"
          :length="length"
          :total-visible="7"
          @input="() => getList()"
        />
      </div>
    </div>
  </v-container>
</template>

<script>
import dayjs from "dayjs";
import * as api_online from "@/api/online-classroom";
import CommentItem from "@/components/commentItem";
import * as api_teacher from "@/api/teacher";
import { getToken } from "@/utils/auth";

export default {
  name: "ClassroomDetail",
  components: { CommentItem },
  data() {
    return {
      id: this.$route.query.id,
      // 详情
      detail: null,
      // 评论内容
      comment: "",
      // 视频播放状态
      videoState: false,
      //限制播放时长
      maxTime: 60,
      // 当前已观看时长
      currentTime: 0,
      // 总时长
      duration: 0,
      // 查询参数
      commentQueryParams: { pageNum: 1, pageSize: 8 },
      // 评论列表
      commentList: [],
      // 头像
      teacherFace : "",
      // 名称
      teacherName : "",
      // 评论总数
      commentTotal: 0,
      // 当前播放视频下标
      currentIndex: 0,
      // 当前要回复的评论
      currentComment: null,
      // 教师身份标签
      identityTags : null,
      // 教师
      teacherInfo : null
    };
  },
  computed: {
    // 是否能发表评论
    canSubmit() {
      return this.comment.length > 0;
    },
    // 分页总页数
    length() {
      return Math.ceil(this.commentTotal / this.commentQueryParams.pageSize);
    },
    // 当前视频的url
    currentVideoUrl() {
      if (this.detail?.eduItemVideos?.length > 0) {
        return this.detail.eduItemVideos[this.currentIndex].videoUrl;
      }
      return null;
    },
  },
  created() {
    this.getDetail();
    this.getCommentList();
  },
  methods: {
    dayjs,
    // 获取动态详情
    async getDetail() {
      try {
        const res = await api_online.getDetail(this.id);
        this.detail = res.data;
        if (res.data.eduCourseTeachers && res.data.eduCourseTeachers.length) {
          const resTeacher = await api_teacher.getInfo(res.data.eduCourseTeachers[0].teacherId)
          this.teacherInfo = resTeacher.data
          this.teacherFace = this.teacherInfo?.face
          this.teacherName = this.teacherInfo?.name
          this.identityTags = this.teacherInfo.identityTags ? JSON.parse(this.teacherInfo.identityTags) : ['']
          console.log("---",this.identityTags)
        }

      } catch (error) {
        console.log(error);
      }
    },
    // 获取当前播放位置
    timeupdate(e) {
      if(!getToken()){
        if (e.target.currentTime - this.maxTime > 0) {
          e.target.currentTime = 1
          this.$dialog.notify.info("游客仅限预览一分钟，请登录后查看完整视频", { position: "top-right", timeout: 2000 });
        }
        if (parseInt(this.currentTime) !== parseInt(e.target.currentTime) && parseInt(e.target.currentTime) % 60 === 0 && parseInt(e.target.currentTime) !== 0) {
          e.target.currentTime = 1
          e.target.play()
        }
      }
    },
    play(e){
      if(!getToken()) {
        if (parseInt(e.target.currentTime) === 0) {
          this.$dialog.notify.info("游客仅限预览一分钟，请登录后查看完整视频", {position: "top-right", timeout: 2000});
        }
      }
    },
    // 获取评论列表
    async getCommentList() {
      const params = { itemId: this.id, ...this.commentQueryParams };
      const res = await api_online.getCommentList(params);
      if (res.code === 200) {
        this.commentList = res.rows;
        this.commentTotal = res.total;
      }
    },
    // 点赞
    async handleLike() {
      const res = await api_online.like(this.id);
      if (res.code !== 200) {
        this.$dialog.notify.error(res.msg, { position: "top-right", timeout: 2000 });
        return;
      }
      this.getDetail();
    },
    // 评论
    async handleComment() {
      const params = { itemId: this.id, content: this.comment };
      const res = await api_online.submitComment(params);
      if (res.code !== 200) {
        this.$dialog.notify.error(res.msg, { position: "top-right", timeout: 2000 });
        return;
      }
      this.$dialog.notify.success("评论成功~", { position: "top-right", timeout: 2000 });
      this.comment = "";
      this.getCommentList();
    },
    // 选集
    handleVideoChange(index) {
      this.currentIndex = index;
    },
    // 回复按钮点击
    handleReply(item) {
      if (this.currentComment?.id === item.id) {
        this.currentComment = null;
      } else {
        this.currentComment = item;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.videoPlayer {
  width: 100%;
  height: 100%;
}
.right-wrapper {
  height: auto;
  .selection-item {
    font-size: 6px;
    border-bottom: 1px solid white;
  }
}

.video-js .vjs-tech {position: relative !important;}
.video-js .vjs-time-control{display:block;}
.video-js .vjs-remaining-time{display: none;}

.bottom-wrapper {
  line-height: 24px;
}
</style>
