import request from './';

/**
 * 登录
 * @param {*} params 
 */
export function login(params) {
    return request({
        url: 'login',
        method: 'post',
        data: params
    })
}
/**
 * 注册
 * @param {*} params 
 */
export function register(params) {
    return request({
        url: 'register',
        method: 'post',
        data: params
    })
}
/**
 * 发送邮箱验证码
 * @param {*} params 
 * @returns 
 */
export function sendEmailCode(params) {
    return request({
        url: 'register/send/mail/code',
        method: 'post',
        data: params
    })
}

/**
 * 获取用户信息
 * @returns 
 */
export function getUserInfo() {
    return request({
        url: 'system/user/token/user',
        method: 'get',
    })
}
