<template>
  <v-container class="py-0">
    <m-title title="在线课堂" />
    <!-- 筛选 -->
    <div class="mt-md-2 text-body-2">
      <div
        class="filter-item px-2 px-md-5 py-3 py-md-4 d-flex align-center"
        v-for="(filter, filterIdx) in filters"
        :key="filterIdx"
      >
        <div class="text-no-wrap">{{ filter.name }}：</div>
        <v-row class="ml-1 ml-md-4">
          <v-col cols="3" md="1" v-for="(item, index) in filter.items" :key="index">
            <div class="pointer text-no-wrap">
              {{ item.name }}
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <!-- 视频列表 -->
    <div class="pt-10">
      <v-row>
        <v-col v-for="(item, index) in list" :key="index" cols="6" md="3">
          <v-card class="pointer" elevation="2" @click="toDetail(item.id)">
            <v-responsive :aspect-ratio="7 / 4">
              <v-img class="img grey" :src="item.cover" />
            </v-responsive>
            <div class="py-3 px-2 text-body-2 text-justify font-weight-bold text-truncate">
              {{ item.title }}
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-pagination
      class="float-right py-10"
      v-model="queryParams.pageNum"
      :length="length"
      :total-visible="7"
      @input="() => getList()"
    />
  </v-container>
</template>

<script>
import MTitle from "@/components/title";
import * as api_online from "@/api/online-classroom";
export default {
  name: "OnlineClassroom",
  components: { MTitle },
  data() {
    return {
      filters: [
        { name: "栏目", items: [{ name: "系列课程" }, { name: "专题课程" }] },
        { name: "系列", items: [{ name: "全部" }] },
        { name: "排序", items: [{ name: "最热" }, { name: "最新" }] },
      ],

      // 查询参数
      queryParams: { pageNum: 1, pageSize: 8 },
      // 列表
      list: [],
      // 总数量
      total: 0,
    };
  },
  computed: {
    // 分页总页数
    length() {
      return Math.ceil(this.total / this.queryParams.pageSize);
    },
  },
  created() {
    this.getList();
  },
  methods: {
    // 获取列表
    async getList() {
      const params = { ...this.queryParams };
      try {
        const res = await api_online.getList(params);
        if (res.code !== 200) {
          return;
        }
        this.list = res.rows;
        this.total = res.total;
      } catch (error) {
        console.log(error);
      }
    },
    // 跳转到视频详情
    toDetail(id) {
      this.$router.push({ path: "/onlineClassroom/detail", query: { id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.img {
  width: 100%;
  height: 100%;
}
.filter-item {
  border-bottom: 1.5px solid #e8e8e8;
}
</style>