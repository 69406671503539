<template>
  <v-container>
    <div class="pb-6" v-for="(item, index) in list" :key="index">
      <m-title :title="item.title" />
      <div class="content" v-html="item.content"></div>
    </div>
    <v-pagination
      class="float-right py-4 py-md-10"
      v-model="queryParams.pageNum"
      :length="length"
      :total-visible="7"
      @input="() => getList()"
    />
  </v-container>
</template>

<script>
import MTitle from "@/components/title";
import * as api_project from "@/api/project";
export default {
  name: "ProjectIntroduction",
  components: { MTitle },
  data() {
    return {
      // 查询参数
      queryParams: { pageNum: 1, pageSize: 5 },
      // 动态的总数量
      total: 0,
      // 列表
      list: [],
    };
  },
  computed: {
    // 分页总页数
    length() {
      return Math.ceil(this.total / this.queryParams.pageSize);
    },
  },
  created() {
    this.getList();
  },
  methods: {
    // 获取列表
    async getList() {
      const params = { ...this.queryParams };
      try {
        const res = await api_project.getList(params);
        if (res.code !== 200) {
          return;
        }
        this.list = res.rows;
        this.total = res.total;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  text-indent: 2em;
  line-height: 1.6;
  text-align: justify;
}
</style>
