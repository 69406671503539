<template>
  <v-container class="py-0">
    <!-- 标题 -->
    <div class="text-h5 text-md-h4 font-weight-bold text-justify">{{ info.title }}</div>
    <!-- 视频 -->
    <v-responsive class="mt-2 mt-md-4 black" :aspect-ratio="16 / 9" width="100%">
      <video
          id="video"
          style="width: 100%; aspect-ratio: 16 / 9"
          v-if="info?.eduItemVideos && info?.eduItemVideos[currentIndex].videoUrl"
          :src="info?.eduItemVideos[currentIndex].videoUrl"
          @timeupdate="timeupdate"
          controls
      />
    </v-responsive>
    <!-- 内容 -->
    <div class="mt-4 text-body-1 text-justify">
      {{ info.content }}
    </div>
    <!-- 点赞和评论数 -->
    <div class="bottom-wrapper mt-8 mb-2 d-flex align-center">
      <div><v-icon>mdi-thumb-up</v-icon></div>
      <span class="ml-1">{{ info.likeCount || 0 }}人点赞</span>
      <div class="ml-6"><v-icon>mdi-comment-processing-outline</v-icon></div>
      <span class="ml-1">{{ info.replyCount || 0 }}人评论</span>
    </div>
    <v-divider />
    <!-- 播放列表 -->
    <div class="px-4 py-4" v-if="info.eduItemVideos?.length > 0">
      <div class="text-body-1 font-weight-bold">播放列表</div>
      <div class="px-2 px-md-4 py-4 d-flex">
        <v-row>
          <v-col cols="3" md="1" v-for="(item, index) in info.eduItemVideos" :key="index">
            <v-btn @click="currentIndex = index">{{ "0" + item.stage }} </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
    <!-- 评论 -->
    <div class="my-11 px-4">
      <span class="text-body-1 font-weight-bold">发表评论</span>
      <v-textarea
        class="mt-2"
        filled
        no-resize
        :hide-details="true"
        placeholder="评论请登录~"
        name="input-7-1"
        v-model="comment"
        hint=""
        background-color="#F5F5F6"
      ></v-textarea>
      <div class="mt-4 d-flex justify-end">
        <v-btn color="primary" :disabled="!canSubmit" @click="handleComment">发表</v-btn>
      </div>

      <div class="comment-area mt-4">
        <span class="text-body-1 font-weight-bold">评论区（{{ commentTotal }}条）</span>
        <div class="comment-list">
          <CommentItem
            v-for="(item, index) in commentList"
            :key="index"
            :show-reply-area="currentComment?.id === item.id"
            :item="item"
            @on-submit="getCommentList"
            @on-reply="handleReply(item)"
          />
        </div>
        <v-pagination
          class="float-right py-10"
          v-model="commentQueryParams.pageNum"
          :length="length"
          :total-visible="7"
          @input="() => getList()"
        />
      </div>
    </div>
  </v-container>
</template>

<script>
import * as api_policy from "@/api/policy-document";
import dayjs from "dayjs";
import CommentItem from "@/components/commentItem";
import { getToken } from "@/utils/auth";

var videoUpdate = () => {
  var video = this.$ref.video;
  const hasToken = getToken();
  if(hasToken)
  console.log(hasToken);
  // if (!hasToken) {
  //   if (e.srcElement.currentTime - this.currTime > 1) e.srcElement.currentTime = this.currTime > this.maxTime ? this.currentTime : this.maxTime
  //   this.currentTime = e.srcElement.currentTime
  //   this.maxTime = this.currentTime > this.maxTime ? this.currentTime : this.maxTime
  // }
  video.addEventListener("timeupdate",function(){
    var timeDisplay;
    var duration;
    //用秒数来显示当前播放进度
    timeDisplay = Math.floor(video.currentTime);
    duration = Math.floor(video.duration);
    console.log('总时长',duration)
    console.log('当前播放的时长',timeDisplay)
  },false);
  console.log(video.duration); //获取视频时长
  console.log(video.currentTime); //获取视频当前播放时间
};
/**
 * 获取视频播放时长
 */
export default {
  name: "PolicyVideoDetail",
  components: { CommentItem },
  data() {
    return {
      id: this.$route.query.id,
      // 详情
      info: {},
      // 当前播放的视频下标
      currentIndex: 0,
      // 评论内容
      comment: "",
      // 回复内容
      reply: "",
      //限制播放时长
      maxTime: 30000,
      //当前时长
      currTime : 0,
      //当前播放时长
      currentTime : 0,
      // 总时长
      duration: 0,
      // 查询参数
      commentQueryParams: { pageNum: 1, pageSize: 8 },
      // 评论列表
      commentList: [],
      // 评论总数
      commentTotal: 0,
      // 当前要回复的评论
      currentComment: null,
    };
  },
  computed: {
    // 是否能发表评论
    canSubmit() {
      return this.comment.length > 0;
    },
    // 分页总页数
    length() {
      return Math.ceil(this.commentTotal / this.commentQueryParams.pageSize);
    },
  },
  created() {
    this.getDetail();
    this.getCommentList();
  },
  mounted(){
    videoUpdate();
  },
  methods: {
    dayjs,
    // 获取详情
    async getDetail() {
      const res = await api_policy.getDetail(this.id);
      if (res.code !== 200) {
        this.$dialog.notify.error(res.msg, { position: "top-right", timeout: 2000 });
        return;
      }
      this.info = res.data;
    },

    // 获取评论列表
    async getCommentList() {
      const params = { itemId: this.id, ...this.commentQueryParams };
      const res = await api_policy.getCommentList(params);
      if (res.code === 200) {
        this.commentList = res.rows;
        this.commentTotal = res.total;
      }
    },
    // 点赞
    async handleLike() {
      const res = await api_policy.like(this.id);
      if (res.code !== 200) {
        this.$dialog.notify.error(res.msg, { position: "top-right", timeout: 2000 });
        return;
      }
      this.getDetail();
    },
    // 评论
    async handleComment() {
      const params = { itemId: this.id, content: this.comment };
      const res = await api_policy.submitComment(params);
      if (res.code !== 200) {
        this.$dialog.notify.error(res.msg, { position: "top-right", timeout: 2000 });
        return;
      }
      this.$dialog.notify.success("评论成功~", { position: "top-right", timeout: 2000 });
      this.comment = "";
      this.getCommentList();
      this.getDetail();
    },
    //限制播放
    timeupdate(e) {
        // console.log(e.srcElement.currentTime - this.currTime);
        // if (e.srcElement.currentTime - this.currTime > 1) {
        //   e.srcElement.currentTime = this.currTime>this.maxTime?this.currTime:this.maxTime;
        //   console.log("快进了");
        // }
        // this.currTime = e.srcElement.currentTime;
        // this.maxTime = this.currTime>this.maxTime?this.currTime:this.maxTime;
        // console.log("视频记录", e.srcElement.currentTime);
        // console.log("本地记录", this.currTime);
      // 获取当前播放位置
        this.currentTime = e.target.currentTime;
        this.duration = e.target.duration ? e.target.duration : 0;
    },
    // 回复按钮点击
    handleReply(item) {
      if (this.currentComment?.id === item.id) {
        this.currentComment = null;
      } else {
        this.currentComment = item;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.video {
  width: 100%;
  aspect-ratio: 16 / 9;
}
.bottom-wrapper {
  line-height: 24px;
}
</style>
