import request from './';

/**
 * 获取列表
 * @param {*} params 
 * @returns 
 */
export function getList(params) {
    return request({
        url: 'system/category/item/list/item/12',
        method: 'get',
        params,
    })
}
/**
 * 获取详情
 * @param {*} id 
 * @returns 
 */
export function getDetail(id) {
    return request({
        url: `system/item/${id}`,
        method: 'get',
    })
}
/**
 * 点赞
 * @param {*} id 
 * @returns 
 */
export function like(id) {
    return request({
        url: `system/like`,
        method: 'post',
        data: {likedId: id, likedType: 'ITEM_LIKE', likedStatus: 1}
    })
}
/**
 * 获取评论列表
 * @param {*} id 
 * @returns 
 */
export function getCommentList(data) {
    return request({
        url: `system/comment/list`,
        method: 'get',
        params: data
    })
}

export function submitComment(data) {
    return request({
        url: `system/comment/add`,
        method: 'post',
        data
    })
}

