<template>
  <v-container>
    <!-- 标题 -->
    <div class="d-flex flex-column align-center">
      <span class="text-h5 text-md-h4 font-weight-bold">{{ detail.title }}</span>
      <div class="mt-4 text-body-2 text--disabled">
        <span>日期：{{ dayjs(detail.createTime).format("YYYY-MM-DD") }}</span>
        <span class="ml-12">来源：{{ detail.sourceName || "--" }}</span>
      </div>
    </div>
    <!-- 内容 -->
    <div class="content mt-4 text-justify" v-html="detail.content"></div>
  </v-container>
</template>

<script>
import dayjs from "dayjs";
import * as api_policy from "@/api/policy-document";
export default {
  name: "PolicyDetail",
  components: {},
  data() {
    return {
      id: this.$route.query.id,
      detail: null,
    };
  },
  computed: {},
  created() {
    this.getDetail();
  },
  methods: {
    dayjs,
    // 获取动态详情
    async getDetail() {
      try {
        const res = await api_policy.getDetail(this.id);
        this.detail = res.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  line-height: 2;
  ::v-deep p {
    text-indent: 2em;
  }
}
.pointer {
  cursor: pointer;
}
</style>