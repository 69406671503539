<template>
  <div class="comment-item-container mt-6 mb-2">
    <!-- 评论 -->
    <div class="comment-wrapper">
      <v-avatar color="primary" size="52">
        <img :src="item.fromAvatar" alt="" />
      </v-avatar>
      <div class="right-wrapper ml-2 ml-md-4">
        <div class="overflow-hidden" style="flex: 1">
          <div class="text-subtitle-1 text-md-h6 font-weight-bold text-truncate">
            {{ item.fromNickName || "注销用户" }}
          </div>
          <span class="text-body-2">{{ item.content }}</span>
          <div class="d-flex justify-space-between mt-1 text-body-2 text--disabled">
            <span>{{ dayjs(item.create_time).format("YYYY-MM-DD") }}</span>
            <div class="d-flex align-center">
              <div class="pointer text--disabled" @click="$emit('on-reply', item)">
                {{ showReplyArea ? "收起" : "回复" }}
              </div>
              <div class="pointer text--disabled ml-5" @click="submitLike(item)">
                <v-icon color="grey lighten-1" size="18">mdi-thumb-up</v-icon>
                <span class="ml-1">{{ item.likedCount || 0 }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 回复框 -->
        <div v-if="showReplyArea" class="reply-wrapper">
          <textarea v-model="reply" :placeholder="`回复：${item.fromNickName || '注销用户'}`" />
          <div class="bottm-wrapper">
            <v-btn color="#4AAF3D" @click="submitReply">
              <span class="white--text">评论</span>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <!-- 评论回复 -->
    <div class="reply-list-wrapper">
      <ReplyItem
        v-for="(reply, replyIdx) in item.eduCommentReplyVOList"
        :key="replyIdx"
        :show-reply-area="currentComment?.id === reply.id"
        :item="reply"
        @on-reply="handleReply(reply)"
        @on-submit="$emit('on-submit')"
      />
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { likeComment, submitCommentReply } from "@/api/trends";
import ReplyItem from "./replyItem";
export default {
  name: "CommentItem",
  components: { ReplyItem },
  props: {
    item: {},
    // 是否显示回复区
    showReplyArea: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 评论回复内容
      reply: "",
      // 当前要回复的评论
      currentComment: null,
    };
  },
  methods: {
    dayjs,
    // 回复按钮点击
    handleReply(item) {
      if (this.currentComment?.id === item.id) {
        this.currentComment = null;
      } else {
        this.currentComment = item;
      }
    },
    // 点赞
    async submitLike() {
      const res = await likeComment(this.item.id);
      if (res.code !== 200) {
        this.$dialog.notify.error(res.msg, { position: "top-right", timeout: 2000 });
        return;
      }
      this.$emit("on-submit");
    },
    // 提交评论回复
    async submitReply() {
      if (!this.reply) {
        this.$dialog.notify.error("请填写评论内容~");
        return;
      }
      const params = { replyId: this.item.id, replyType: "COMMENT", content: this.reply };
      const res = await submitCommentReply(params);
      if (res.code !== 200) {
        this.$dialog.notify.error(res.msg, { position: "top-right", timeout: 2000 });
        return;
      }
      this.reply = "";
      this.$emit("on-submit");
      this.$emit("on-reply", this.item);
    },
  },
};
</script>

<style lang="scss" scoped>
.comment-item-container {
  width: 100%;
  .comment-wrapper {
    display: flex;
    flex-direction: row;
  }
  .right-wrapper {
    flex: 1;
  }
  .reply-wrapper {
    margin-top: 10px;
    padding: 18px 16px;
    background-color: #f5f5f6;
    border-radius: 8px;
    textarea {
      width: 100%;
      height: 60px;
      &:focus-visible {
        outline: none;
      }
    }
    .bottm-wrapper {
      display: flex;
      flex-direction: row-reverse;
    }
  }
  .reply-list-wrapper {
    margin-left: 56px;
  }
}
</style>